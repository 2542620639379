<template>
	<el-drawer title="工单详情" :visible="drawerShow" :direction="`rtl`" v-if="drawerShow" size="60%" :before-close="visibleClose" append-to-body>
		<el-tabs class="detail" v-model="activeName" :tab-position="`left`">
			<el-tab-pane label="工单详情" name="first">
				<transition>
					<DetailInfo v-if="activeName === 'first'" :orderServiceOgr="orderServiceOgr" :orderId="orderId" />
				</transition>
			</el-tab-pane>
			<el-tab-pane label="工单跟踪" name="second" style="height: 100%">
				<transition>
					<OrderProgess v-if="activeName === 'second'" :orderId="orderId" />
				</transition>
			</el-tab-pane>
		</el-tabs>
	</el-drawer>
</template>

<script>
import DetailInfo from './detailComponents/workDetailInfo'
import OrderProgess from './detailComponents/buildTrack'
export default {
	// 订单详情
	nama: 'orderDetail',
	components: {
		OrderProgess,
		DetailInfo,
	},
	props: {
		drawerShow: {
			type: Boolean,
			default: false,
		},
		orderId: {
			type: [String, Number],
			default: null,
		},
		orderServiceOgr: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			activeName: 'first',
			clickOrder: '659254291428675584',
		}
	},
	methods: {
		visibleClose() {
			this.$emit('update:drawerShow', false)
			this.activeName = 'first'
		},
	},
}
</script>

<style lang="scss" scoped>
.detail {
	font-size: 16px;
}
</style>
