import request from '../utils/request'
import config from './config'

// 订单跟踪
export function getOrderTrack(params) {
	return request({
		url: `${config.CIVIL_HOST}/hksOrder/userTrack`,
		method: 'get',
		params,
	})
}

// 街道级联拖养服务
export function backCascader2(params) {
	return request({
		url: `${config.CIVIL_HOST}/hksOrder/backCascader2`,
		method: 'get',
		params,
	})
}

// 订单详情
export function webInfo(params) {
	return request({
		url: `${config.CIVIL_HOST}/hksOrder/webInfo`,
		method: 'get',
		params,
	})
}

// 根据床位id查询服务订单
export function getOrderByBedId(data) {
	return request({
		url: `${config.CIVIL_HOST}/hksOrder/getOrderByBedId`,
		method: 'post',
		data,
	})
}

// 用户管辖街道
export function streetList(data) {
	return request({
		url: `${config.CIVIL_HOST}/hksOrder/streetList`,
		method: 'post',
		data,
	})
}

// 用户下面的管辖服务机构
export function serviceNameList(data) {
	return request({
		url: `${config.CIVIL_HOST}/hksOrder/serviceNameList`,
		method: 'post',
		data,
	})
}

// 订单分页查询
export function backPage(data) {
	return request({
		url: `${config.CIVIL_HOST}/hksOrder/backPage`,
		method: 'post',
		data,
	})
}

// 订单分页查询
export function getServiceObjectTypeList(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionHksWorkOrder/getServiceObjectTypeList`,
		method: 'get',
		params,
	})
}


